import Development from "../src/images/1.svg";
import Ui_ux from "../src/images/2.svg";
import Migrate from "../src/images/3.svg";


const Sdata_h=[
    {
        imgsrc: Development,
        title: "Entreprises",
        fields:"We are offering ingenious digital services and consultation for big, medium and small businesses. We break down complex products and processes to make them simple and easy to understand. We develope final digital product that will be not only visually attractive, but also easy to understand for your business and help your business take a new approach to how they conduct their business in digital era.",
        
    },

    {   imgsrc:Ui_ux,
        title: "Startups",
        fields:"This is time where most of digital idea are introducing globally, we assist startup and listening to there concepts which can be digitally implementing with help of technology and help them in development of their product from MVP to final product. We are team of innovative people who are there for you, to think out of box. We will get creative and innovative development for your startup which will satisfy your need. ",
    },

    {
        imgsrc: Migrate,
        title: "Agencies",
        fields:"We helps agencies to develop there websites, mobile application, server and security to make your business one step ahead. Web application  are the basic need to manage any type of business in ethical way, lot of tools are available on internet but its hard to find all in one tool for your requirement in business. We are developing for Empowering companies, Consulting firm, Independent software vendors.",
    },

]

export default Sdata_h;
