import React from "react";
import { NavLink } from "react-router-dom";
import Hcard from "./Hcard";
import Sdata from "./Sdata";
import Reeact from "../src/images/Reeact.png"
import Vue from "../src/images/Vue.png"
import Nodejs from "../src/images/Nodejs.png"
import MangoDB from "../src/images/MongoDB.png"
import Firebase from "../src/images/Firebase.png"
import Bootstrap from "../src/images/Bootstrap.png"
import Angular from "../src/images/Angular.png"


const Services =  () => {

    return(
    <>
   <div className="my-5"> 
        <h1 className=" text-center "> Our Services </h1>
   </div>

   <div className="container-fluid mb-5">
        <div className='row' >
            <div className="col-10 mx-auto">
                <div className='row gy-4'>
                   {
                       Sdata.map((val, ind) =>{
                        return< Hcard key={ind} imgsrc={val.imgsrc} title={val.title} fields={val.fields} line1={val.line1} line2={val.line2} line3={val.line3}/>

                   })
                   }
                    
                </div>
            </div>
        </div>
    </div>

    <section className="container-fluid pdg2" >
        <div className="col-10 mx-auto ">
        <h1 className=" text-center ex1">Techonology</h1>
            <div className="row d-flex justify-content-center flex-row">
               
                
           
                <div className="col-lg-2 order-1 order-lg-2 client-item ">
                <img src={Reeact} className="displayed" height="100" alt="img5"  />
                </div>

                <div className="col-lg-2 order-1 order-lg-2 client-item ">
                <img src={Vue} className="displayed" height="100" alt="img2"  />
                </div>

                <div className="col-lg-2 order-1 order-lg-2 client-item ">
                <img src={Nodejs} className="displayed" height="100" alt="img3"  />
                </div>
                <div className="col-lg-2 order-1 order-lg-2 client-item ">
                <img src={MangoDB} className="displayed" height="100" alt="img4"  />
                </div>
                <div className="col-lg-2 order-1 order-lg-2 client-item ">
                <img src={Bootstrap} className="displayed" height="100" alt="img6"  />
                </div>
                <div className="col-lg-2 order-1 order-lg-2 client-item ">
                <img src={Firebase} className="displayed" height="100" alt="img6"  />
                </div>
                <div className="col-lg-2 order-1 order-lg-2 client-item ">
                <img src={Angular} className="displayed" height="100" alt="img6"  />
                </div>
            
                
                </div>
                </div>
        </section>

    </>
    );
};

export default Services;