import React from "react";
import { NavLink } from "react-router-dom";
import  web from "../src/images/home-01.png";
import  home3 from "../src/images/home-03.png";
import Common from "./Common";
import Card from "./Card";
import Sdata_h from "./Sdata_h"
import  img1 from "../src/images/20.svg";
import home2 from "../src/images/home-02.png"
import Testimonials from "./Testimonial";
import Clients from "./Clients";
import img5 from "../src/images/Client1.png"
import img2 from "../src/images/Client2.jpeg"
import img3 from "../src/images/Client3.png"
import img4 from "../src/images/Client4.png"
import img6 from "../src/images/Client5.png"
import Cardh from "./Cardh";



const Home =  () => {

    return(
    <>
    <Common name='Grow our busness with' 
    name2="get consultation for your website from our experienced developer that will help you to find a specific solution for your Business !"
    brandname="SCOPEWIT"
    imgsrc={web} 
    visite="/contact" 
    btname="Lets Talk"/>

        <section className="pdg1"></section>
      
         <div classname="my-5 ">
            <h1 className="text-center ex1" > We Work With </h1>
         </div>
            
              <div className="container-fluid mb-5 pdg1">
                 <div className='row' >
                     <div className="col-10 mx-auto">
                        <div className='row gy-4 text-center'>
                          {
                            Sdata_h.map((val, ind) =>{
                            return< Cardh key={ind} imgsrc={val.imgsrc} title={val.title} fields={val.fields}/>

                            })
                          }


                         </div>
                     </div>
                </div>
            </div>
                          
            <section id="header" className="d-flex align-items-center bg-primary-light">

<               div className="container-fluid ">
                    <div className="row">
                        <div className="col-10 mx-auto">
                             <div className="row">

                                <div className=" col-lg-6 order-1 order-lg-2 d-flex justify-content-center flex-column">
                                <h1> Let Us Help You Attract More Clients  </h1>
                                <h2 className="my-3">  Working with us you can lift the web reputation of your company higher than ever. We can offer you all marketing of your brand  </h2>
                                     <div className="mt-3">
                                    <NavLink to ="/services" className="btn-get-started"> Learn More </NavLink>
                                     </div>
                                 </div>
                    

                                 <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
                                   <img src={home2}className="img-fluid animated" alt="Common img"  />

                     </div>
                     </div> 
                 </div>
             </div>
         </div>


        </section>
                   
        <div className="slider-item pdg2">
        <header className="App-header">
        <h1 className=" text-center ex1">Testimonials</h1>
        <Testimonials />
      </header>
        </div>
        
        <section className="container-fluid pdg2" >
        <div className="col-10 mx-auto ">
        <h1 className=" text-center ex1">Our Clients</h1>
            <div className="row d-flex justify-content-center flex-row ">
               
                
           
                <div className="col-lg-2 order-1 order-lg-2 client-item ">
                <img src={img5} className="displayed" height="100" alt="img5"  />
                </div>

                <div className="col-lg-2 order-1 order-lg-2 client-item ">
                <img src={img2} className="displayed" height="100" alt="img2"  />
                </div>

                <div className="col-lg-2 order-1 order-lg-2 client-item ">
                <img src={img3} className="displayed" height="100" alt="img3"  />
                </div>
                <div className="col-lg-2 order-1 order-lg-2 client-item ">
                <img src={img4} className="displayed" height="100" alt="img4"  />
                </div>
                <div className="col-lg-2 order-1 order-lg-2 client-item ">
                <img src={img6} className="displayed" height="100" alt="img6"  />
                </div>
                
                </div>
                </div>
        </section>
        <section >
        <Common name='We work with clients all over the world' 
        name2=" helps clients inside and outside of the US to achieve success by using our services and solutions."
    imgsrc={home3} 
    visite="/contact" 
    btname="Get Quote"/>
        </section>          

    </>


    );
};

export default Home;