import React from "react";
import { NavLink } from "react-router-dom";
import web from "../src/images/177.svg";
import Sdata from "./Sdata"



const Cardh =  (props) => {

    return(
    <>
   
   <div className="col-md-4 col-10 mx auto">
                    <div className="card" >
                         <img src={props.imgsrc} className="card-img-top" alt="probs.imgsrc"/>
                            <div className="card-body">
                                 <h2 className="card-title font-weight-bold">{props.title}</h2>
                                     <p className="card-text"> {props.fields} </p>
                            </div>
                     </div>
                    </div>
 
    </>
    );
};

export default Cardh;

