import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals'; // eslint-disable-next-line
import {BrowserRouter} from "react-router-dom";


ReactDOM.render(
  <> 
    <BrowserRouter>

    <App />

    </BrowserRouter>

  </> ,

  document.getElementById('root')
);
