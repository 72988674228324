import react from "react";
import { AiOutlineMail } from 'react-icons/ai';
import { BiMessageDetail } from 'react-icons/bi';
import { BsSearch } from 'react-icons/bs';
import { MdOutlineAnalytics } from 'react-icons/md';
import { AiOutlineFlag } from 'react-icons/ai';
import { GiForwardSun } from 'react-icons/gi';
import {BsFillArrowUpCircleFill} from 'react-icons/bs';


const workapi = [
    
   
    {   
        id: 1,
        logo: <AiOutlineMail/>,
        title: "Excellent at engaging",
        info:" Every communication has a conversion goal. Goals vary from project to project, but our objective is always the same: engage the user on an enjoyable journey that ends in conversion."
    },

    {   
        id: 2,
        logo: <BiMessageDetail/>,
        title: "Cooperative",
        info:" We care about our client’s brands and the digital products we create. We favor long-term relations, often becoming digital partner for all aspects of our clients digital ventures."
    },

    {   
        id: 3,
        logo: <BsSearch/>,
        title: "Good Listeners",
        info:" We ask (many) questions. A deep understanding of our clients’ business processes and audiences is the foundation of our practice. We support our creative process with data and qualitative research, bringing clarity to the project. "
    },

    {   
        id: 4,
        logo: <MdOutlineAnalytics/>,
        title: "Making you look good",
        info:" First impressions matter a lot, so we make sure your audience retains a positive impression from the moment they interact with your brand. "
    },

    {   
        id: 5,
        logo: <AiOutlineFlag/>,
        title: "Simplifiers",
        info:" We break down complex products and processes to make them simple and easy to understand. Your final digital product will be not only visually attractive, but also easy to comprehend/understand. "
    },

    {   
        id: 6,
        logo: <BsFillArrowUpCircleFill />,
        title: "Reliable",
        info:" We are problem solvers at heart. We enjoy simplifying and suggesting sustainable solutions to our clients. We always deliver, most of the time on time. "
    },
       
        
]



export default workapi;