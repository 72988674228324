import React from "react";
import Sdata from "./Sdata"

const Hcard =  (props) => {

    return(
    <>
   
      <div className="col-md-4 col-10 mx auto">
        <div class="card service_card_h" >
          <img src={props.imgsrc} class="card-img-top" alt="props.imgsrc"/>
            <div class="card-body">
              <h5 class="card-title">{props.title}</h5>
              <p class="card-text">{props.fields}</p>
            </div>
          <ul class="list-group list-group-flush">
               <li class="list-group-item">{props.line1}</li>
               <li class="list-group-item">{props.line2}</li>
              <li class="list-group-item">{props.line3}</li>
          </ul>
        </div>
    </div>
 
    </>
    );
};

export default Hcard;

 