import React, { useState } from "react";


const Contact =  () => {

    const [ userData, setUserData] = useState({
        fullname:"",
        Phone:"",
        Email:"",
        msg:"",
    });


    let name, value;
    const postUserData = (event) =>{
        name = event.target.name;
        value = event.target.value;

        setUserData({...userData, [name]:value});
    }

    const submitData = async (event) => {
        event.preventDefault();
       const {Email, Phone,fullname ,msg} = userData;
        const res = await fetch("https://scopewit-ef342-default-rtdb.europe-west1.firebasedatabase.app//userDataRecord.json",
        {
        method: "POST",
        headers: {
            "Content-Type" :"application/json",
        },
        body: JSON.stringify({
            Email, 
            Phone,
            fullname,
            msg, 
        }),

        }
        );
        if (res) {
            setUserData({
                fullname:"",
                Phone:"",
                Email:"",
                msg:"",
            });
            alert("Data Stored");
        }
        else{
            alert("Please fill the data")
        }
    };


    return(
    <>
    <div className="my-5">
    <h1 className="text-center"> Contact Us </h1>
    </div>
        <div className="container contact_div">
            <div className="row">
                <div className="col-md-6 col-10 mx-auto">

                    <form >
                    <div className="mb-3">
                    
                     <input type="text" className="form-control" id="fullname" 
                     name="fullname"
                     value={userData.fullname}
                     onChange={postUserData}
                     placeholder="Enter your Name"/>
                    </div>

                    <div className="mb-3">
                    
                    <input type="text" className="form-control" id="Phone" 
                     name="Phone"
                     value={userData.Phone}
                     onChange={postUserData}
                     placeholder="Enter your Phone"/>
                    </div>

                    <div className="mb-3">
                    
                    <input type="text" className="form-control" id="Email" 
                     name="Email"
                     value={userData.Email}
                     onChange={postUserData}
                     placeholder="Enter your email"/>
                    </div>

                        <div className="mb-3">
                        
                        <textarea className="form-control" id="msg" rows="3"
                        name="msg"
                        value={userData.msg}
                        onChange={postUserData}
                        ></textarea>
                        </div>

                        <div className="form-check contact-input-field">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                            <label className="form-check-label" className="main-hero-para " for="flexCheckChecked">I agree that SCOPEWIT may contact me at the email address or the phone Number above </label>
                        </div>

                        <div className="col-12 contact-input-field">
                        <button className="btn btn-outline-primary"  type="submit" onClick={submitData}>Submit </button>
                        </div>

                    </form>

                </div>

            </div>

        </div>
    </>
    );
};

export default Contact;