import React from "react";
import wweb from "../src/images/23.svg"
import  logo from "../src/images/S_logo.png";
import { FaFacebookF } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { AiFillTwitterSquare } from 'react-icons/ai';
import wave from "../src/images/wave.svg"


const Footer = () => {
  return(
            <>
            <section className="pdg2">
            <div className="col 12 fotter " >
            <img src={wave} alt="wave" className=" img-fluid " />
           
        <div className="col-10 mx-auto ">
            <div className="row d-flex justify-content-center flex-row ">
           
                <div className="col-lg-2 order-1 order-lg-2 fotter">
                <img src={logo} alt="logo" className="center"  width="150" />
                <br/>
                <p className="text-center">
                   <h6>Scopewit Sp.zo.o.</h6>
                   Polna 38, Gajkow 
                   <br/>
                   Poland
                   <br/>
                   KRS 0000170088
                </p>
                </div>

                <div className="col-lg-3 order-1 order-lg-2 fotter ">
                <p className="text-center">
                <h3>Quick links</h3>
                   <br/>
                 <a className="fhref" href="/">Home</a>
                   <br/>
                  <a className="fhref" href="/About">About</a>
                   <br/>
                  <a className="fhref" href="/Services">Services</a>
                </p>
                </div>

                <div className="col-lg-3 order-1 order-lg-2 fotter ">
                <p className="text-center">
                   
                <h3>Policy</h3> <br/>
                <a className="fhref" href="/policy">Privacy Policy</a>
                   <br/>
                   <a className="fhref" href="/policy">Terms</a>
                  <br/>
                  <a className="fhref" href="/policy">Quality management policy</a>
                </p>
                </div>
                <div className="col-lg-3 order-1 order-lg-2 fotter ">
                <p className="text-center">
                   <h3>Contact us</h3> <br/>
                   info@scopewit.com 
                   <br/>
                  +48 516 700 066
                   <br/>
                   <FaFacebookF style={{color: '#22A0E3'}} size={30}/> <FaLinkedin style={{color: '#22A0E3'}} size={36} /> <AiFillTwitterSquare style={{color: '#22A0E3'}} size={40}/>
                </p>
                </div>
                
                
                </div>
                </div>
        
           
             <footer className="w-100 text-center ">
                <p> Copyright © 2021 Scopewit | All right are reserve | Terms and condition</p>
                 
             </footer>
             
             </div>

             </section>
            
            </>
           
        );
    
  };

  export default Footer;
