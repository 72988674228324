import react from "react";

const policy=() => {
    return(
        <>
        <div className="work-container container">
            <div className="row ">
                <div className="col-10 mx-auto wrapper">
                    <div className="row">
                        <h1> Privacy Policy </h1>
                        <h5>Who we are </h5>
                            <p>Our website address is: https://www.scopewit.com.</p>

                            <h5> What personal data we collect and why we collect it
                    Comments</h5>
                    <p>When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection.

                    An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. The Gravatar service privacy policy is available here: https://scopewit.com/policy/. After approval of your comment, your profile picture is visible to the public in the context of your comment. </p>

                    <h5>Media</h5>
                    <p>If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website. </p>

                    <h5>Contact forms</h5>
                    <p> Scopewit may collect your personal data that you provide to it, for example, when you contact Scopewit electronically or respond to an email from Scopewit. Scopewit may collect your business contact details when you exchange business cards with Scopewit representatives at meetings and events, or when you provide your contacts to sign up for Scopewit events.

                    When submitting your request/inquiry on Scopewit websites, you may be asked for information such as, but not limited to, your full name, email address, phone number, company you work for or own and/or country where you reside or work.

                    When contacting Scopewit through live chats on its websites, you may be asked for information such as, but not limited to, your name and email address. This is needed when we are not ready to answer you immediately and need more time to handle your request. When you give Scopewit your personal data in live chat, we assume that you consent to processing it by Scopewit.

                    By using cookies, Scopewit may collect details of your visits to its websites such as, but not limited to, the pages you visited, the date, time and duration of your visit and/or your IP address as well as the browser you use and its version, your operating system and its version, your city and state.

                    It is Scopewit policy to collect only the minimum information required to handle and complete your request/inquiry or to reach other purposes for which Scopewit processes your personal data. If you believe Scopewit websites have collected excessive information about you, please contact Scopewit through the request/inquiry form to raise your concerns.

                    Scopewit does not intend to collect sensitive information through its websites unless it is legally required to do so. Examples of sensitive information include race or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, physical or mental health, genetic data, biometric data, sexual life or sexual orientation, and criminal records. Scopewit asks that you do not provide sensitive data of such nature when using its websites.

                    When you submit your request/inquiry on Scopewit websites, Scopewit uses your data on the basis of your consent. In other cases, when you voluntarily exchange business contact details with Scopewit, Scopewit sends you information or marketing communications on the basis of its legitimate interests in maintaining a business relationship with you or companies you work for or represent and/or on the basis of your consent (when it is technically possible to collect your freely given, specific, informed and unambiguous consent). Cookies are used on the basis of your consent and/or Scopewit legitimate interest described below. </p>

                    <h5>Cookies</h5>
                    <p> If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.

                    If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.

                    When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.

                    If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day. </p>

                    <h5>Embedded content from other websites</h5>
                    <p>Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.

                    These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website. </p>

                    <h5>How long we retain your data</h5>
                    <p>Scopewit will retain your personal information in its systems only for as long as Scopewit needs it, given the purposes for which it was collected, or as required to do so by law. </p>

                    <h5>What rights you have over your data</h5>
                    <p>If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes. </p>

                    <h5>Who may access your personal data</h5>
                    <p>Scopewit may disclose your personal data to (1) its employees who are in contact with you or who are responsible for marketing, (2) other companies of Scopewit (some of them are located outside the European Union), (3) third-party service providers.

                    Statistical data about Scopewit websites’ visitors may be accessed by (1) Scopewit employees in marketing and/or IT departments who are responsible for analysing such data and for improving Scopewit websites, (2) other companies of Scopewit (some of themScopewit are located outside the European Union), (3) third-party service providers. </p>

                    <h5>Purposes for which Scopewit uses your personal data</h5>
                    <p> Scopewit may use your data to interact with you and/or respond to your requests/inquiries, to negotiate, perform or facilitate a contract with you or related to you, to send you invitations to events it organizes, to share with you information you might be interested in and/or to inform you about its services. Scopewit may also use your personal data to comply with law, regulations, public authority requests or court orders.

                    These are examples of purposes for which Scopewit may use personal data you provide to it:

                    to administer and manage Scopewit websites;
                    to understand how people use the features and functions of its websites in order to improve user experience;
                    to determine the company, organisation, institution, or agency that you work for or represent or with which you are otherwise associated;
                    to develop its businesses and services;
                    to analyze the usage of Scopewit websites;
                    any other purposes for which you provided information to Scopewit.
                    Scopewit websites do not collect or compile personally identifying information for sale to third parties for consumer marketing purposes. </p>

                    <h5>Transfers to third countries</h5>
                    <p>Scopewit is a multinational group of companies branch in India. For this reason, your personal data may be transferred to and stored outside the country where you are located. This includes countries outside the European Economic Area with no adequate level of personal data protection (third countries).

                    As some of the countries where our offices are situated are not yet recognized by the European Commission as countries with an adequate level of personal data protection, these offices have access to personal data on the basis of instruments that cover the European Union requirements for the transfer of personal data to data controllers/processors outside the European Union (Standard Contractual Clauses approved by the European Commission). You may ask Scopewit to inspect copies of these clauses by contacting its data protection officer. </p>

                    <h5>Third-party providers</h5>
                    <p>When Scopewit transfers personal data, it does so for the purposes for which the information has been collected/submitted, for the purposes listed above, for the administration and maintenance of its websites and associated systems, and/or other internal or administrative purposes. Scopewit also may transfer personal data to third parties providing identity management, website hosting and management, data analysis, data backup, security and storage services.

                    Scopewit works only with those third-party providers that are bound to maintain appropriate levels of security and confidentiality, to process personal data only as instructed by Scopewit and to flow those same obligations down to their sub-processors. </p>

                    <h5>Rights in relation to your personal data</h5>
                    <p>Under your local laws and the European Union legislation, you may exercise the following rights by submitting your request/inquiry on Scopewit websites:

                    right to require access to your personal data,
                    right to require rectification of your personal data,
                    right to require erasure of your personal data,
                    right to withdraw your consent regarding processing of your personal data.
                    In some cases, you may also have a right (1) to request restriction on processing of your personal data or (2) to object to processing of your personal data.

                    There are cases when Scopewit can retain copies of the personal data you requested it to erase. They concern the necessity to protect legitimate interests of Scopewit or third parties, comply with governmental orders, resolve disputes, troubleshoot problems, or enforce any agreement you have formed with Scopewit. </p>

                    <h5> Right to lodge a complaint with a supervisory authority</h5>
                    <p>You have the right to lodge a complaint with your local data protection regulator if you think your privacy rights have been violated. </p>

                    <h5>Contact Scopewit</h5>
                    <p>If you have any questions or comments regarding this Privacy and Cookies Policy, you can contact Scopewit via the request/inquiry form on this website.</p>

                    </div>
                </div>
            </div>
        </div>

        <div className="work-container container">
            <div className="row ">
                <div className="col-10 mx-auto wrapper">
                    <div className="row">
                    <h1> Terms </h1>
                    <h5>Transfers to third countries</h5>
                    <p>Scopewit is a multinational group of companies branch in India. For this reason, your personal data may be transferred to and stored outside the country where you are located. This includes countries outside the European Economic Area with no adequate level of personal data protection (third countries).

                    As some of the countries where our offices are situated are not yet recognized by the European Commission as countries with an adequate level of personal data protection, these offices have access to personal data on the basis of instruments that cover the European Union requirements for the transfer of personal data to data controllers/processors outside the European Union (Standard Contractual Clauses approved by the European Commission). You may ask Scopewit to inspect copies of these clauses by contacting its data protection officer. </p>

                    <h5>Third-party providers</h5>
                    <p>When Scopewit transfers personal data, it does so for the purposes for which the information has been collected/submitted, for the purposes listed above, for the administration and maintenance of its websites and associated systems, and/or other internal or administrative purposes. Scopewit also may transfer personal data to third parties providing identity management, website hosting and management, data analysis, data backup, security and storage services.

                    Scopewit works only with those third-party providers that are bound to maintain appropriate levels of security and confidentiality, to process personal data only as instructed by Scopewit and to flow those same obligations down to their sub-processors. </p>

                    <h5>Rights in relation to your personal data</h5>
                    <p>Under your local laws and the European Union legislation, you may exercise the following rights by submitting your request/inquiry on Scopewit websites:

                    right to require access to your personal data,
                    right to require rectification of your personal data,
                    right to require erasure of your personal data,
                    right to withdraw your consent regarding processing of your personal data.
                    In some cases, you may also have a right (1) to request restriction on processing of your personal data or (2) to object to processing of your personal data.

                    There are cases when Scopewit can retain copies of the personal data you requested it to erase. They concern the necessity to protect legitimate interests of Scopewit or third parties, comply with governmental orders, resolve disputes, troubleshoot problems, or enforce any agreement you have formed with Scopewit. </p>

                    <h5> Right to lodge a complaint with a supervisory authority</h5>
                    <p>You have the right to lodge a complaint with your local data protection regulator if you think your privacy rights have been violated. </p>


                    </div>
                </div>
            </div>
        </div>

        <div className="work-container container">
            <div className="row ">
                <div className="col-10 mx-auto wrapper">
                    <div className="row">
                    <h1> Quality Management Policy </h1>
                    <h5>About</h5>
                    <p>Scopewit Sp.zo.o. (Company) is a leading provider of digital solutions and IT services with development centers in Wroclaw and global delivery infrastructure including its products. The company renders the whole spectrum of custom software development services, systems integration, consulting and support. Scopewit focuses on developing technologically complex but easy-to-use solutions supporting key business processes of our customers. Over the years, we have built a proven project management methodology to ensure transparency and control of each project phase, and guarantee the delivery of expected results on time. Our technology expertise allows us to deliver software development projects of any scale, complexity, and type.</p>

                            <h5>Corporate goals</h5>
                            <p>Delivering software development, modernization, deployment, maintenance, and support services, we set the following goals:
                            Provision of customer pipeline that allows for maintaining business continuity, acquiring industry and technology expertise;
                            Development of partnerships with the existing clients based on the knowledge of their business needs and pain points while striving to offer full-cycle services;
                            Improvement of the project implementation quality to ensure business retention and further business development. We hold that a successful project is a foundation for long-term cooperation, whereas a low-quality project can lead to termination of
                            contract obligations.</p>

                            <h5>Product Quality</h5>
                            <p>We make sure quality about services and deliverables to all of our users and existing customers in the best and flexible way. Improvement of our product and implementation quality to ensure business retention and further product update. We hold that a successful project is a foundation for long-term cooperation, whereas a low-quality Product can lead to termination of contract obligations.</p>

                            <h5>Corporate Value Customer</h5>

                            <p> Our success is measured by our customers’ success. We do our best to help our customers achieve maximum results, foster competitiveness and innovation by applying information technology.</p>

                            <h5>Employees</h5>
                            <p>In order to build a team of motivated IT professionals, we are committed to the growth and engagement of our employees, their development and well-being by creating a respectful, rewarding and productive work environment.</p>

                            <h5> Quality</h5>
                            <p> Being a quality-oriented software development company, Itransition sets up and continuously maintains high standards of software engineering

                            </p>

                    </div>
                </div>
            </div>
        </div>
        </>

    );
};
export default policy;