import web from "../src/images/177.svg";
import React from "../src/images/500.svg";
import Design from "../src/images/499.svg";
import Uiux from "../src/images/498.svg";
import Marketing from "../src/images/497.svg";
import Content from "../src/images/496.svg";
import Hcard from "./Hcard";


const Sdata=[
    {
        imgsrc: web,
        title: "Web Application Developmet",
        fields: "We have great experience in making web applications. Be it in ReactJS, Angular, Vue, or Core JavaScript. Scopewit has a greatly talented and experienced team to create a simple yet robust, easy to use, user-friendly, and responsive web application. We also restructure the existing web application. We solve the existing bugs in web applications.            ",
        line1: " UX/UI Designs",
        line2: " Web application development",
        line3: "Web application deployment",
    },

    {
        imgsrc: React,
        title: "ReactJs Development",
        fields:"Scopewit is an experienced ReactJS application developer. We use ReactJS as a library to create single page complex web applications. From designing the application to integrating the APIs, we make sure that the application is perfect in every way. We also restructure the existing ReactJS application and fix bugs in the existing ReactJS application.",
        line1:" UX/UI of web application",
        line2:"lState management using ReduxJS",
        line3:"API integration",
    },

    {
        imgsrc: Design,
        title: "Graphic Design",
        fields:"the Best Graphic design of business need understands how each customer wants his business to get the best. We understand the emotions of our customers and the value of work that we provide. We believe in continuous innovations and endless learning. We make sure that our designers stay up-to-date with the latest designs, softwares, and trends. We design graphics, banners, paperleaf and menu for all types of industries",
        line1:"Logo/ Brand design",
        line2:"Graphic/ Banner",
        line3:"Menu/ Advertise",
    },

    {
        imgsrc: Uiux,
        title: "Front end ",
        fields:"Choose offshore Frontend web development services and get skilled professionals, state-of-art infrastructure, and latest technologies. Our project managers will ensure that the job gets done with the highest level of professionalism and at market leading prices.",
        line1:"Bootstrap CSS Framework",
        line2:"Vue.js",
        line3:"Angular",
    },

    {
        imgsrc: Marketing,
        title: "Marketing",
       fields: "Best SEO service providing company in Poland. Scopewit will provide high quality SEO services and optimize your website to bring in search results. With our SEO strategies and techniques that will help your website to rank in the first pages of the search engines. We will provide the power of words to your website and optimize the search engines to crawl it and achieve higher rankings and get noticed. We are there by providing the best Search Engine Optimization among the various ways to drive traffic on your web portal, Search Engine Optimization is considered as the best.",
       line1:"SEO",
        line2:"Social Media Marketing",
        line3:"Lead generation stragaties",
    },

    {
        imgsrc: Content,
        title: "Content Writting",
        fields:"“Content is the King” when it comes to the digital space, and no one can neglect the fact. We believe that words have the power to convey the message, express the emotions and do much more. So, if words are quite precious, then it is quite important to hire a professional content writer to cater to all your content writing needs. We offer content that talks to your audience and sells on your behalf. Whether you are a small firm or a large conglomerate, whatever you need in terms of content, we have got it all.",
        line1:"Web Content",
        line2:"Marketing content",
        line3:"Wide language Content",
    },

]

export default Sdata;
