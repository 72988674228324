import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import S1 from "../src/images/google.png";

export default class Testimonials extends Component {
  render() {
    return (
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
        >
          
        <div>
        <img src={S1} />
          <div className="myCarousel">
          <h3>Pooja bhandarkar</h3>
          <p>Its was pleasure working with your organization. Client is really happy as Product is very friendly and flexible in use. Even your suggestion was really helpful for our product. Looking forward to work with you more. Thanks</p>
        </div>
        </div>

        <div>
          <img src={S1} />
          <div className="myCarousel">
          <h3>Patrycja Grzeszczak</h3>
          <p>Najlepszy serwis, świetny kontakt z firmą. Dziękuję !</p>
        </div>
        </div>

        <div>
          <img src={S1} />
          <div className="myCarousel">
            <h3>Adam Gnioska</h3>
          <p>It's amazing company  </p>
        </div>
        </div>

        <div>
          <img src={S1} />
          <div className="myCarousel">
            <h3>Ihor Cherep</h3>
            <h4>Designer</h4>
          <p>I think this is a great place</p>
        </div>
        </div>

        <div>
          <img src={S1} />
          <div className="myCarousel">
            <h3>Sneha Thakre</h3>
          <p>Best creative and innovative ideas and thinking for growing up businesses.</p>
        </div>
        </div>

        <div>
          <img src={S1} />
          <div className="myCarousel">
            <h3>Harshad Bindra</h3>
          <p>Best Experience!!Creative Team. Knowledgeable Personalities.Grow your Business!!</p>
        </div>
        </div>
      </Carousel>
    );
  }
}