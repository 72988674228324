import React from "react";
import Common from "./Common";
import { NavLink } from "react-router-dom";
import  web from "../src/images/64.svg"; 
import  pro1 from "../src/images/pro1.png"; 
import  pro2 from "../src/images/pro2.png"; 
import  pro3 from "../src/images/pro3.png"; 
import  pro4 from "../src/images/pro4.png"; 
import  pro5 from "../src/images/pro5.png"; 
import  pro6 from "../src/images/pro6.png"; 
import  pro7 from "../src/images/pro7.png"; 
import  pro8 from "../src/images/pro8.png"; 
import  pro9 from "../src/images/pro9.png"; 
import  pro10 from "../src/images/pro10.png"; 
import  pro11 from "../src/images/pro11.png"; 
import  pro12 from "../src/images/pro12.png"; 
import { GiForwardSun } from 'react-icons/gi';
import {BsFillArrowUpCircleFill} from 'react-icons/bs';
import Universal_card from "./Universal_card"


const About =  () => {

    return(
    <>
    <Common name='About us' 
    imgsrc={web} 
    visite="/contact" 
    btname="contact now"/>
    

    <section  className="container-fluid pdg1 ">
        <div className="col-10 mx-auto ">
            <h1 className="text-center"> What is Scopewit ? </h1>
            <p className="text-center"> Scopewit is full-cycle based software development company with headquarters in Wroclaw, Poland. Scopewit provides professional website and mobile application development solutions and personalized tailor-made services for business and individual customers. Since Scopewit establishment, it has served development services to enterprises and individuals of different sizes. We focus on Website development, mobile application development, hosting services and maintenance. With good understanding of business global requirement Scopewit is believing in good content writing for clients which is provided in English, Polish, Ukraine and German language, to make our clients good impression on local as well as local market that helps in good search engine optimization ( SEO ) search results. Scopewit is known for quality of our user experience and development. We commit to take care of your development and digital assets, while our clients can look at other aspects of their business.</p>
        </div>
        <div className="col-10 mx-auto ">
            <h1 className="text-center"> What Scopewit Do ? </h1>
            <p className="text-center"> Scopewit is a development based company working with major domain for your business that are website development, mobile application development, DevOps, content writing and SEO, server and maintenance. Outsourcing of It project is also a part of our company, we outsources projects based on java and python technology. Scopewit provides consultation for digital services of your business which includes technology, platform and practical development bases that helps our customer to grow by their impressive online identity.</p>
        </div>
        <div className="col-10 mx-auto ">
            <h1 className="text-center"> We're a passionate team of Web-developer experts </h1>
            <p className="text-center"> Scopewit is a provider of digital solutions and IT services with development centers in Poland and global delivery infrastructure. The company renders the whole spectrum of custom website development services, systems integration, maintenance and support. Scopewit focuses on developing technologically complex but easy-to-use solutions supporting key business processes of our customers. We are built a proven project management methodology to ensure transparency and control of each project phase, and guarantee the delivery of expected results on time. Our technology expertise allows us to deliver software development projects of any scale, complexity and type. We've built our reputation on providing quality REACT sites to professional clients in wide range of industries. We work closely with our clients to ensure that the best experience is delivered, and we want your feedback!</p>
        </div>
        <div className="col-10 mx-auto ">
            <h1 className="text-center"> Our Mission </h1>
            <p className="text-center"> We are in modern world of digitalization where future is completely transforming in to digital entity. Scopewit as company looking for revolution in market with its product & development of custom Website to enhance business to its next level.</p>
        </div>
       
    </section>

    <div className="fotter">
        <h1 className="text-center"> Our Work</h1>
    </div>


<div className="container">
    <div className="row ">
        <div className="col-lg-6 col-12 mx-auto d-flex justify-content-center pdg1">
            <div className="box">
                <div className="imbox">
                    <img src={pro1} alt="web " hight="200"/>
                </div>
                <div className="content_card">
                    <h3>Misa Hindusa</h3>
                    <p>It is a local food restaurant working in Poland. This project work with most of the platform integration all together in visiting a website like reservation, food ordering, and payment automatic and placing an order in the kitchen over with cloud including POS application integrated with the food ordering system.</p>
                </div>
            </div>
        </div>
        <div className="col-lg-6 col-12 mx-auto d-flex justify-content-center pdg1">
            <div className="box">
                <div className="imbox">
                    <img src={pro2} alt="web " width="300"/>
                </div>
                <div className="content_card">
                <h3>Find Closeby</h3>
               <p> FindCloseBy is a startup in Poland. We are a local search engine that provides users to find local services. We help users search for local services and trusted professionals for all their needs. We are team of young, passionate people working tirelessly to make things simple for users to search services offered locally not just for natives but for foreigners as well, giving them a platform to search the services offered in their preferred language.</p>
                </div>
            </div>
        </div>
        <div className="col-lg-6 col-12 mx-auto d-flex justify-content-center pdg1">
            <div className="box">
                <div className="imbox">
                    <img src={pro3} alt="web " width="300"/>
                </div>
                <div className="content_card">
                <h3>Printme India</h3>
                <p>Printme India, came into existence thriving on the idea of providing various customized products and clothing using the latest printing technology. It takes local business ideas over an online market that spread over India. Our team deal with the automation of customization order over several processes.</p>
                </div>
            </div>
        </div>
        <div className="col-lg-6 col-12 mx-auto d-flex justify-content-center pdg1">
            <div className="box">
                <div className="imbox">
                    <img src={pro4} alt="web " width="300"/>
                </div>
                <div className="content_card">
                <h3>Faslove</h3>
                <p>Faslove is one store to another in search of the gagets and products Not only mobiles. This is developed on wordpress with smooth integration of 3rd party tools to make orders mostly automated. This Store only deals in India operated from Nagpur and expanding in most of the states in India with a there online platform.</p>
                </div>
            </div>
        </div>
        <div className="col-lg-6 col-12 mx-auto d-flex justify-content-center pdg1">
            <div className="box">
                <div className="imbox">
                    <img src={pro5} alt="web " width="300"/>
                </div>
                <div className="content_card">
                <h3>Nagpur Stores</h3>
                <p>Nagpur store is local search engine for the peoples like to have fantastic search to find the products in orange city, it gives complete understanding of buing products from this city</p>
                </div>
            </div>
        </div>
        <div className="col-lg-6 col-12 mx-auto d-flex justify-content-center pdg1">
            <div className="box">
                <div className="imbox">
                    <img src={pro6} alt="web " width="300"/>
                </div>
                <div className="content_card">
                <h3>Vivhs.in</h3>
                <p>Vivhs is​ a MVP wich brings concept and ​alternative to the outdated wedding planning process. A one-stop-shop for all things weddings, you can find inspiratio​​n​, ​ideas ​and vendors within​ your​ budget​.  </p>
                </div>
            </div>
        </div>
        <div className="col-lg-6 col-12 mx-auto d-flex justify-content-center pdg1">
            <div className="box">
                <div className="imbox">
                    <img src={pro7} alt="web " width="300"/>
                </div>
                <div className="content_card">
                <h3>Sonu Art</h3>
                <p>Sonu art is a art gallary store. It takes local business ideas over an online market that spread over India. Our team deal with the automation of customization order over several processes.</p>
                </div>
            </div>
        </div>
        <div className="col-lg-6 col-12 mx-auto d-flex justify-content-center pdg1">
            <div className="box">
                <div className="imbox">
                    <img src={pro8} alt="web " width="300"/>
                </div>
                <div className="content_card">
                <h3>Royal India</h3>
                <p>It is a food restaurant chain working in Poland. This project work with most of the platform integration all together in visiting a website like reservation, food ordering, and payment automatic and placing an order in the kitchen over with cloud including POS application integrated with the food ordering system. </p>
                </div>
            </div>
        </div>
        <div className="col-lg-6 col-12 mx-auto d-flex justify-content-center pdg1">
            <div className="box">
                <div className="imbox">
                    <img src={pro9} alt="web " hight="200"/>
                </div>
                <div className="content_card">
                <h3>Meraki Fabrics</h3>
                <p>E-commerce is revolutionizing the way we all shop in India. Meraki fabrics is one store to another in search of the latest fabrics when you can find it on the Internet in a single click? Not only mobiles. Meraki fabrics everything you can possibly imagine, from trending fabrics like Suits, silk, Embroidery, and unstretched suits accessories to they all got them in this store. This is developed on WordPress with smooth integration of 3rd party tools to make orders mostly automated. This Store only deals in India operated from Nagpur and expanding in most of the states in India with a there online platform.</p>
                </div>
            </div>
        </div>
        <div className="col-lg-6 col-12 mx-auto d-flex justify-content-center pdg1">
            <div className="box">
                <div className="imbox">
                    <img src={pro10} alt="web " width="300"/>
                </div>
                <div className="content_card">
                <h3>Best of Bharat</h3>
                <p>Best of Bharat is a restaurant located in Wroclaw, Poland. We have great challenges to developing a project for them which should match with restaurant theme and traditional culture of that kitchen, It was a combination of an online ordering system with integration of a mobile android application this ordering system gives realtime tracking of website visitor and order made from a website which was very handy to operate by the manager of the restaurant so food can be delivered in exact offered time by restaurant and reservation system was also part of this website.</p>
                </div>
            </div>
        </div>
        <div className="col-lg-6 col-12 mx-auto d-flex justify-content-center pdg1">
            <div className="box">
                <div className="imbox">
                    <img src={pro11} alt="web " hight="200"/>
                </div>
                <div className="content_card">
                <h3>Bhagwat Heritage</h3>
                <p>This website is about Indian culture and and social work, Challenges our team accepted in this website development is managing server for number of user engagement at live video audience, to make navigation in simplified way to get understand by devotee.</p>
                </div>
            </div>
        </div>
        <div className="col-lg-6 col-12 mx-auto d-flex justify-content-center pdg1">
            <div className="box">
                <div className="imbox">
                    <img src={pro12} alt="web " width="300"/>
                </div>
                <div className="content_card">
                <h3>SWITS</h3>
                <p>Swits is a one-stop solution to plan out tasks for your employees and monitor their work performances in the smarter and the most effective ways. The solution we delivered for this portal is designing the UX concept and responsive web design - WordPress based development. With the working function of dual-language  English and Polish</p>
                </div>
            </div>
        </div>
    </div>
</div>

    <section>
    <div className="container-fluid " >
            <div className="row">
                 <Universal_card/>
            </div>
    </div>
    </section>

    
    <section>
    <div className="container-fluid Rbox" >
    <Common name='Rank up your Website' 
    imgsrc={web} 
    name2="We help client to rank up there website with better SEO practices"
    visite="/contact" 
    btname="contact now" className="pdg1"/>
    </div>
    </section>

    <section id="header" className="d-flex align-items-center pdg2">

            <div className="container-fluid how_we_work">
                <div className="row">
                    <div className="col-10 mx-auto">
                          <div className="row">
                                <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column ">
                                    <h1> How we works  </h1>
                                    <h2 className="my-3">  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque placerat eros ac finibus congue. Integer consectetur </h2>

                                    <div className="mt-3">
                                    <NavLink to ="#" className="btn-get-started"> Read more </NavLink>

                                     </div>

                                 </div>
                                      <div className=" col-lg-6 order-1 order-lg-2">
                                         <div className="wrapper how_we_work"> 
                                         <div className="center-line">< BsFillArrowUpCircleFill className="scroll-icon" /></div>
                                            <div className="row row1">
                                             <section>
                                                 < GiForwardSun className="icon" />
                                                 <div className="details">
                                                     <span className="title"> Initial Consultation</span>
                                                 </div>
                                                 <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet laboriosam quas eius neque nam corporis tempore omnis perspiciatis</p>
                                                
                                             </section>
                                             </div>
                                             <div className="row row1">
                                             <section>
                                             < GiForwardSun className="icon" />
                                                 <div className="details">
                                                     <span className="title"> Task Analysis</span>
                                            
                                                 </div>
                                                 <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet laboriosam quas eius neque nam corporis tempore omnis perspiciatis</p>
                                                 
                                             </section>
                                             </div>
                                             <div className="row row1">
                                             <section>
                                             < GiForwardSun className="icon" />
                                                 <div className="details">
                                                     <span className="title"> Campaign Development</span>
                                                     
                                                 </div>
                                                 <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet laboriosam quas eius neque nam corporis tempore omnis perspiciatis</p>
                                                
                                             </section>
                                             
                                             </div>
                                             <div className="row row1">
                                             <section>
                                             < GiForwardSun className="icon" />
                                             <i className="GiForwardSun"></i>
                                                 <div className="details">
                                                     <span className="title "> Task Analysis</span>
                                                   
                                                 </div>
                                                 <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet laboriosam quas eius neque nam corporis tempore omnis perspiciatis</p>
                                                 
                                             </section>
                                             </div>
                                        </div> 
                                     </div>
                                 </div> 

                             </div>
                         </div>
                     </div>

        </section>

    </>
    );
};

export default About;