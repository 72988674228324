import react, { useState }from "react";
import { AiOutlineMail } from 'react-icons/ai';
import { BiInfoCircle } from "react-icons/bi";
import { MdTitle } from "react-icons/md";
import workapi from "./Adata.js"


const Universal_card = () =>{
    const [workData, setWorkData] = useState(workapi);
    console.log(workData);

    return(
        <>
        <section>
            <div className="work-container container">
                <div>
                    <h1  className="main-heading text-center">Why to choose Scopewit</h1>
                    <div className="row">
                        {workData.map((CurElem) =>{ 
                            const {id, logo, info, title} = CurElem
                            return(
                                <>
                            <div className="col-12 col-lg-4 text-center work-container-subdiv">
                            <i className="iconstyles">{logo}</i>
                            <h2 className="sub-heading"> {title}</h2>
                            <p className="main-hero-para w-100"> {info}</p>
                         
                        </div>
                        </>
                        );
                        })}
                    </div>
                </div>
            </div>
        </section>
        </>
    );
};

export default Universal_card;